<template>
  <div id="app">
    <header-component/>
    <main-component/>
    <footer-component/>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';

export default {
  name: 'App',
  components: {
    HeaderComponent: () => import(/* webpackChunkName: "header" */'./components/Header/'),
    MainComponent: () => import(/* webpackChunkName: "main" */'./components/Main/'),
    FooterComponent: () => import(/* webpackChunkName: "footer" */'./components/Footer/'),
  },
  // created() {
  //   const targetDate = new Date('2023-07-01T00:00:00');
  //   const currentDate = new Date();
  //
  //   if (currentDate >= targetDate) {
  //     this.startInfoBanner();
  //   }
  // },
  // methods: {
  //   startInfoBanner() {
  //     setTimeout(() => {
  //       this.$root.$emit('bv::show::modal', 'info-modal');
  //     }, 800)
  //   }
  // },
}
</script>

<style lang="scss">

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  color: $dark;
  @include _lg {
    font-size: $font-size-small;
  }

  section {
    padding: 80px 0;
    @include _lg {
      padding: 30px 0;
    }
  }
}

.modal-open {
  pointer-events: none;
}

#info-modal .close {
  display: none;
}
</style>
