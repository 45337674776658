import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@babel/polyfill'
import 'mutationobserver-shim'
import './plugins/bootstrap-vue'
import Vue from 'vue'
import App from './App.vue'

import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import VueMask from 'v-mask'
import VueScrollTo from 'vue-scrollto';

Vue.config.productionTip = false;

Vue.use(VueMask, {
    placeholders: {

        Я: /[0-9a-zA-Zа-яА-Я]/, // cyrillic letter as a placeholder
    }
})

vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement => createElement('span', {class: {'icon-select-close': true}}),
    },
    OpenIndicator: {
        render: createElement => createElement('span', {class: {'icon-select': true}}),
    },
});

Vue.use(Vuelidate);
Vue.component('v-select', vSelect);
Vue.use(VueMask);
Vue.use(VueScrollTo);


new Vue({
    render: h => h(App),
}).$mount('#app');
